import { inject, Injectable, signal } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { filter, from, startWith } from 'rxjs';
import { map } from 'rxjs/operators';
import dayjs from 'dayjs';
import 'dayjs/locale/es.js';
import { StorageKey, StorageService } from '@app/services/storage/storage.service';
import { ActivatedRoute } from '@angular/router';
import { isNotNullish } from '@app/utils';

export enum KnownLang {
  en = 'en',
  es = 'es',
}

type LangDictRecord = {
  key: KnownLang;
  name: string;
};

export const LangDict: LangDictRecord[] = [
  {
    key: KnownLang.en,
    name: 'English',
  },
  {
    key: KnownLang.es,
    name: 'Español (Latin America)',
  },
];

@Injectable({
  providedIn: 'root',
})
export class LocaleService {
  readonly route = inject(ActivatedRoute);
  readonly i18n = inject(TranslateService);
  readonly storage = inject(StorageService);

  readonly reloading = signal(false);

  private initialized = false;

  get currentLocale(): string {
    return this.detectLang().toString();
  }

  readonly currentLocale$ = from(this.i18n.onLangChange).pipe(
    startWith(this.currentLocale),
    map(() => this.currentLocale)
  );

  initLocale() {
    if (this.initialized) return;

    this.addLangs(this.isTranslationEnabled() ? LangDict.map(({ key }) => key) : [KnownLang.en]);
    this.setDefaultLocale(KnownLang.en);

    this.setLocale(this.detectLang());

    this.initialized = true;

    this.route.queryParamMap
      .pipe(
        map(map => map.get('lang')),
        filter(isNotNullish)
      )
      .subscribe(lang => this.setLocale(lang));
  }

  setDefaultLocale(localeId: string) {
    this.i18n.setDefaultLang(localeId);
  }

  setLocale(localeId: string) {
    const lang = this.mapToKnownLang(localeId);

    this.i18n.use(lang);
    this.storage.setItem(StorageKey.lang, lang);
    dayjs().locale(lang);
  }

  addLangs(langs: string[]) {
    this.i18n.addLangs(langs);
  }

  private detectLang(): KnownLang {
    let localeId = this.storage.getItem(StorageKey.lang);

    if (!localeId) {
      localeId = navigator.language;
    }

    return this.mapToKnownLang(localeId);
  }

  private mapToKnownLang(lang: string): KnownLang {
    if (lang.startsWith('en')) return KnownLang.en;
    if (lang.startsWith('es') && this.isTranslationEnabled()) return KnownLang.es;

    if (!this.isKnownLang(lang)) return KnownLang.en;

    return lang as KnownLang;
  }

  private isKnownLang(lang: string): boolean {
    if (!this.isTranslationEnabled()) return lang === KnownLang.en;
    return LangDict.map(({ key }) => key.toString()).includes(lang);
  }

  private isTranslationEnabled(): boolean {
    return false;
  }
}
