import { inject, Injectable } from '@angular/core';
import { Event, GuardsCheckEnd } from '@angular/router';
import { AppConfiguration } from '@app/services/configuration/app.configuration';

declare let gtag: any;

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  private readonly appConfig = inject(AppConfiguration);
  private ready!: Promise<void>;

  constructor() {
    // init all analytics libraries
    this.ready = new Promise(resolve => {
      resolve();
    });
  }

  async setupUser(userId: number) {
    // setup user
    if (!this.appConfig.production) return;
    await this.ready;

    gtag('config', 'G-C3PBFY5NL7', {
      user_id: `${userId}`,
    });
  }

  async pageView(event: Event): Promise<void> {
    if (!this.appConfig.production) return;
    if (this.isBrokenLink(event)) return;

    await this.ready;
  }

  async quizStepStarted(
    name: string,
    id: string,
    step: number,
    selected_option?: string
  ): Promise<void> {
    if (!this.appConfig.production) return;
    await this.ready;
    gtag('event', 'quiz', {
      name, //название квиза
      step, //шаг квиза (номер), экраны, которые просто с картинкой тоже учитываются
      selected_option, //опция, которую выбрали на ПРЕДЫДУЩЕМ шаге
      id, //id шага
    });
  }

  async quizCompleted(): Promise<void> {
    if (!this.appConfig.production) return;
    await this.ready;
    // console.log('[Analytics] Quiz completed');
  }

  async quizStepCompleted(stepId: string, answer: string): Promise<void> {
    if (!this.appConfig.production) return;
    await this.ready;
    // console.log('[Analytics] Quiz step completed', stepId, answer);
  }

  private isBrokenLink(event: Event): string | boolean | undefined {
    if (event instanceof GuardsCheckEnd) {
      return event && event.url && event.url.includes('sale%3F');
    }

    return undefined;
  }
}
